.contact {
    padding-bottom: 2.6875rem;
    &__persons,
    &__image {
        width: calc(50% - 2.6875rem);
        display: flex;
        flex-direction: column;
        @media (max-width: 62.5rem) {
            width: 100%;
        }
    }
    &__persons {
        gap: 2.6875rem;
    }
}

.person {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 2.6875rem;
    border-bottom: 4px solid var(--primary);
    &__title {
        text-transform: uppercase;
        font-weight: 700;
    }
    &__info {
        display: flex;
        gap: 0.75rem;
    }
    &__field {
        color: hsl(0, 0%, 60%);
    }
    @media (max-width: 62.5rem) {
        &:last-child {
            border: none;
            padding-bottom: 0;
        }
    }
}

.accident {
    padding-bottom: 2.6875rem;
}