.button {
    display: block;
    width: fit-content;
    background-color: var(--secondary);
    padding: 0.5rem 1rem;
    &__label {
        font-family: 'Bebas_Neue', sans-serif;
        font-size: 1.75rem;
        line-height: 2.6rem;
        color: var(--light);
    }
    &:hover {
        background-color: var(--primary);
    }
}