.header {
    position: fixed;
    z-index: 4;
    width: 100%;
    border-bottom: 4px solid var(--primary);
    background-color: var(--light);
    margin-bottom: 5.375rem;
    &__container {
        position: relative;
        max-width: 74.8125rem;
        padding: 2.125rem 1.25rem;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        @media (max-width: 62.5rem) {
            justify-content: space-between;
            padding: 1.25rem;
        }
    }
    &__logo {
        position: absolute;
        left: 0;
        top: 0;
        padding: 1.25rem;
        @media (max-width: 62.5rem) {
            position: relative;
            padding: 0;
        }
    }
    & .hamburger {
        display: none;
        @media (max-width: 62.5rem) {
            display: flex;
            z-index: 2;
        }
    }
    @media (max-width: 62.5rem) {
        .operations {
            display: none;
            z-index: 1;
            flex-direction: column;
            width: 100%;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            padding: 2rem;
            &::before {
                content: '';
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: var(--light);
                backdrop-filter: blur(2px);
                z-index: -1;
            }
            &.open {
                display: flex;
                justify-content: center;
            }
        }
    }
}