.footer {
    border-top: 4px solid var(--primary);
    margin-top: 5.375rem;
    background-color: var(--light);
    & p, a, span{
        font-size: 1rem;
        margin-bottom: 0;
        @media (max-width: 62.5rem) {
            font-size: .875rem;
        }
    }

    /* Container for the footer */
    &__container {
        position: relative;
        max-width: 74.8125rem;
        padding: 5.375rem 1.25rem;
        margin: 0 auto;
        display: flex;
        gap: 4rem;
        flex-wrap: wrap;
        justify-content: space-between;
        & h3 {
            padding-bottom: 2rem;
        }
        @media (max-width: 62.5rem) {
            flex-direction: column;
            padding: 1.25rem;
        }
    }

    /* Section flexed */
    &__section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 62.5rem) {
            align-items: center;
            text-align: center;
        }
    }

    /* Socials of FC Doggen */
    &__socials {
        display: flex;
        gap: 1.5rem;
        justify-content: center;
        margin-top: 2.6875rem;
        & a {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            border: 4px solid var(--primary);
            @media (max-width: 62.5rem) {
                padding: .75rem;
            }
            & img {
                width: 1.5rem;
                height: 1.5rem;
                @media (max-width: 62.5rem) {
                    width: 1.25rem;
                    height: 1.25rem;
                }
            }
            &:hover {
                border-color: var(--secondary);
            }
        }
    }

    /* Sitemap of the website */
    &__sitemap {
        flex-direction: column;
        align-items: flex-start;
        gap: .55rem;
        font-family: 'Montserrat', sans-serif;
        @media (max-width: 62.5rem) {
            align-items: center;
            text-align: center;
        }
    }

    /* Affiliated links */
    &__links {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2.6875rem;
        & a {
            width: 10rem;
            &:hover {
                cursor: pointer;
                opacity: .7;
            }
        }
    }

    /* Info */
    &__info {
        display: flex;
        flex-direction: column;
        gap: .3rem;
        @media (max-width: 62.5rem) {
            align-items: center;
            text-align: center;
        }
    }

    /* Copyright */
    &__copyright {
        margin-top: 2.6875rem;
        & p,
        & a {
            font-size: .875rem;
            line-height: 1.5rem;
            @media (max-width: 62.5rem) {
                font-size: .75rem;
            }
        }
    }
    & .benoit:hover {
        color: #3fd9ee;
    }
}
