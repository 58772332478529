.menubutton {
    all: unset;
    &:hover {
        cursor: pointer;
    }
    & .hamburger {
        width: 25px;
        min-width: 25px;
        &.open {
            display: none;
        }
    }
    & .cross {
        width: 21px;
        min-width: 21px;
        display: none;
        position: relative;
        bottom: 4px;
        &.open {
            display: block;
        }
    }
}
