.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup {
    display: flex;
    gap: 1.75rem;
    flex-direction: column;
    padding: 1.75rem;
    margin: 1.25rem;
    max-width: 60rem;
    width: 100%;
    height: auto;
    max-height: 85vh;
}

.header {
    display: flex;
    justify-content: space-between;
    gap: 3.5rem;
    align-items: center;
}

.button {
    padding: 10px 20px;
    background-color: var(--secondary);
    color: var(--light);
    border: none;
    cursor: pointer;
    &:hover {
        background-color: var(--primary);
    }
    @media (max-width: 62.5rem) {
        display: none;
    }
}

.mobileButton {
    display: none;
    @media (max-width: 62.5rem) {
        display: block;
        background-color: var(--secondary);
        padding: 0.5rem 1rem;
        border: none;
        cursor: pointer;
        &:hover {
            background-color: var(--primary);
        }
        &__label {
            font-family: 'Bebas_Neue', sans-serif;
            font-size: 1.75rem;
            line-height: 2.6rem;
            color: var(--light);
        }
    }
}

.content {
    overflow-y: auto;
    max-height: 70vh;
}