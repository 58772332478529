.sponsors {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 2.6875rem;
    @media (max-width: 62.5rem) {
        justify-content: center;
    }
    & img {
        max-width: 14rem;
        width: 100%;
        @media (max-width: 62.5rem) {
            max-width: 10rem;
        }
        &:hover {
            opacity: .7;
        }
    }
}