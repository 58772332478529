.loadingcontainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--light);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 5;
}

.spinner {
    border: 4px solid var(--primary);
    border-top: 4px solid hsl(46, 100%, 77%);
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loadinglogo {
    position: absolute;
    width: 5rem;
    height: 5rem;
}