.navigation {
    display: flex;
    align-items: center;
    gap: 2.6875rem;
    font-size: 1.375rem;
    font-family: 'Bebas_Neue', sans-serif;
    &__link:hover::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 4px;
        background-color: var(--primary);
    }
    @media (max-width: 62.5rem) {
        flex-direction: column;
    }
}
