.redirect {
    display: block;
    width: fit-content;
    &__label {
        display: flex;
        align-items: center;
        gap: 1.25rem;
        &::after {
            content: '';
            position: relative;
            left: 0;
            bottom: 0;
            transition: 0.16s ease-in-out;
            display: block;
            width: 1.25rem;
            height: 1.25rem;
            background-image: url(../../../assets/images/arrow.svg);
            background-size: cover;
            background-repeat: no-repeat;
        }
        &:hover::after {
            left: 4px;
            bottom: 4px;
            transition: 0.16s ease-in-out;
        }
    }
}
