.result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.3125rem;
    border: solid 4px var(--dark);
    padding: 1rem 1.6rem;
    gap: 1rem;
    &__home,
    &__away,
    &__score {
        width: 30%;
    }
    &__home,
    &__away {
        text-transform: uppercase;
        @media (max-width: 62.5rem) {
            display: none;
        }
    }
    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.3rem;
        font-size: 1rem;
        padding-bottom: 1rem;
        line-height: 1.6rem;
    }
    &__date {
        text-align: center;
        padding: 0.3rem 0.8rem;
        color: var(--light);
        background-color: var(--secondary);
        width: fit-content;
    }
    &__score {
        flex: 1;
        text-align: center;
    }
    &__away {
        text-align: right;
    }
    &__teams {
        display: none;
        padding-top: 1.2rem;
        @media (max-width: 62.5rem) {
            display: block;
        }
    }
    &__comment {
        text-transform: uppercase;
        color: hsl(34, 100%, 50%);
        padding-top: 1rem;
    }
}