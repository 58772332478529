/*
===========================
GLOBAL STYLES
===========================
*/

:root {
  /* Visual design colors */
  --dark: hsl(209, 20%, 21%);
  --light: hsl(50, 43%, 97%);
  --primary: hsl(47, 91%, 63%);
  --primary-light: hsl(47, 83%, 95%);
  --secondary: hsl(221, 39%, 49%);
}

@font-face {
  font-family: 'Bebas_Neue';
    src: url('../assets/fonts/Bebas_Neue/BebasNeue-Regular.woff2') format('woff2'),
      url('../assets/fonts/Bebas_Neue/BebasNeue-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat/Montserrat-Medium.woff2') format('woff2'),
      url('../assets/fonts/Montserrat/Montserrat-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Italic';
    src: url('../assets/fonts/Montserrat/Montserrat-MediumItalic.woff2') format('woff2'),
      url('../assets/fonts/Montserrat/Montserrat-MediumItalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

*::selection {
  background-color: var(--primary);
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.125rem;
  line-height: 2rem;
  @media (max-width: 62.5rem) {
    font-size: 1rem;
    line-height: 1.7rem;
  }
  color: var(--dark);
  background-color: var(--light);
}

h1 {
  font-family: 'Bebas_Neue', sans-serif;
  font-size: 8.6rem;
  line-height: 8.6rem;
  @media (max-width: 62.5rem) {
    font-size: 6.4rem;
    line-height: 6.4rem;
  }
  @media (max-width: 37.5rem) {
    font-size: 4.4rem;
    line-height: 4.4rem;
  }
}

h2 {
  font-family: 'Montserrat-Italic', sans-serif;
  font-size: 2.4rem;
  line-height: 2.4rem;
  @media (max-width: 62.5rem) {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}

h3 {
  display: inline-block;
  width: fit-content;
  font-family: 'Bebas_Neue', sans-serif;
  font-size: 1.75rem;
  line-height: 1.875rem;
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: var(--primary);
    margin-top: 8px;
  }
}

h4 {
  font-size: 1.5rem;
  line-height: 2rem;
  padding-bottom: 1.75rem;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

a {
  text-decoration: none;
  position: relative;
  color: var(--dark);
  &.active::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: var(--primary);
  }
}

p {
  margin-bottom: 1.5rem;
}

.list__item {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.75rem;
  &::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background-color: var(--primary);
  }
}

/* Container with max-width */
.container {
  position: relative;
  max-width: 74.8125rem;
  padding: 3.375rem 1.25rem;
  margin: 0 auto;
  &--top {
    padding-top: 15rem;
    @media (max-width: 62.5rem) {
      padding-top: 10rem;
    }
  }
}

/* Header for the container */
.container__header {
  display: flex;
  justify-content: space-between;
  gap: 2.6875rem;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 2.6875rem;
  @media (max-width: 62.5rem) {
    justify-content: center;
  }
}

/* Box with background color and border */
.box {
  background-color: var(--primary-light);
  border: 4px solid var(--primary);
  height: 100%;
}

/* Flex container with two sections */
.two-parts,
.team {
  display: flex;
  gap: 5.375rem;
  flex-wrap: wrap;
  @media (max-width: 62.5rem) {
    gap: 2.6875rem;
    justify-content: center;
  }
}

.team {
  padding-bottom: 5.375rem;
}