.impression {
    margin-bottom: 2rem;
    & img {
        border: 4px solid var(--primary);
        width: 100%;
    }
    & .caption {
        display: flex;
        position: relative;
        padding: 1.2rem;
        font-size: 0.95rem;
        line-height: 1.4rem;
        opacity: 0.6;
        &::before {
            content: '';
            position: absolute;
            top: -1.8rem;
            left: 0;
            width: 4px;
            height: 100%;
            background-color: var(--primary);
            margin-top: 1.5rem;
        }
        @media (max-width: 62.5rem) {
            font-size: 0.8rem;
            line-height: 1.2rem;
        }
    }
}
