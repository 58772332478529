.member {
    position: relative;
    border: 4px solid var(--secondary);
    width: calc(35% - 5rem);
    min-width: 20rem;
    text-align: center;
    height: 100%;
    &__image {
        width: 100%;
        height: 100%;
        max-height: 16.875rem;
        object-fit: cover;
        overflow: hidden;
        & img {
            transform: scale(1.05);
        }
    }
    &__shirtNumber {
        position: absolute;
        top: 0.5rem;
        right: 0.8rem;
    }
    &__name {
        padding: 1.3125rem;
        background-color: var(--secondary);
        color: var(--light);
        font-family: 'Bebas_Neue', sans-serif;
        font-size: 1.75rem;
        line-height: 1.875rem;
        margin-bottom: 0;
    }
    &__position {
        padding: 1.3125rem;
        background-color: var(--primary);
        margin-bottom: 0;
    }
}