.last-results {
    background-color: var(--primary-light);
    padding: 2.125rem 0;
    &__hightlight {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        & .highlight__teams {
            display: none;
            @media (max-width: 62.5rem) {
                display: block;
            }
        }
        & .highlight__score {
            text-align: center;
            width: fit-content;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1.25rem;
            & .highlight__info {
                display: flex;
                flex-direction: column;
            }
            & h1 {
                width: 100%;
                background-color: var(--primary);
                padding: 0.6rem 2rem 0.4rem;
                @media (max-width: 62.5rem) {
                    width: inherit;
                }
            }
        }
        & .highlight__comment {
            text-transform: uppercase;
            color: hsl(34, 100%, 50%);
        }
        & .highlight__home,
        & .highlight__away {
            flex: 1;
            @media (max-width: 62.5rem) {
                display: none;
            }
        }
        & .highlight__away {
            text-align: right;
        }
    }
}

.ranking, .event {
    display: flex;
    flex-direction: column;
    width: calc(50% - 2.6875rem);
    @media (max-width: 62.5rem) {
        width: 100%;
    }
}

.ranking {
    &__content {
        padding: 1.75rem;
        display: flex;
        flex-direction: column;
        gap: 1.75rem;
        text-align: center;
        justify-content: space-around;
    }
    &__place,
    &__matches {
        padding-bottom: 1.75rem;
        border-bottom: 4px solid var(--primary);
    }
    &__place {
        @media (min-width: 62.5rem) {
            font-family: 'Bebas_Neue', sans-serif;
            font-size: 7.6rem;
            line-height: 7.6rem;
        }
    }
    &__matches,
    &__goals {
        display: flex;
        justify-content: space-between;
        padding-right: 1.75rem;
        padding-left: 1.75rem;
    }
    & .column {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1.75rem;
        &__title {
            font-family: 'Bebas_Neue', sans-serif;
            font-size: 1.75rem;
            line-height: 1.875rem;
        }
    }
    & .trophy {
        max-width: 6.25rem;
        width: 100%;
        @media (max-width: 62.5rem) {
            max-width: 4.375rem;
        }
    }
    & .points::before,
    & .difference::before {
        content: "";
        display: block;
        position: absolute;
        left: -1.75rem;
        width: 4px;
        height: 100%;
        background-color: var(--primary);
        @media (max-width: 62.5rem) {
            display: none;
        }
    }
}

.event {
    & img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    &__content {
        position: relative;
    }
    &__info {
        position: absolute;
        bottom: 0;
        background-color: var(--primary);
        padding: 1.3125rem;
        display: flex;
        flex-direction: column;
        gap: .75rem;
    }
}

.announcement {
    font-size: 1.05rem;
    line-height: 1.5rem;
    background-color: var(--secondary);
    color: var(--light);
    position: absolute;
    top: 5.4rem;
    width: 100%;
    & a {
        color: var(--light);
        text-decoration: underline;
    }
    &__content {
        position: relative;
        max-width: 74.8125rem;
        padding: 1rem 1.25rem;
        padding-top: 2rem;
        margin: 0 auto;
        text-align: right;
        padding-left: 13rem;
        @media (max-width: 62.5rem) {
            padding-left: 1.25rem;
        }
    }
    @media (max-width: 62.5rem) {
        font-size: 0.9rem;
        line-height: 1.4rem;
        top: 7.6rem;
    }
    @media (max-width: 37.5rem) {
        top: 7rem;
    }
}