.background {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    max-height: 80rem;
    z-index: -1;
    overflow: hidden;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: var(--bg-image);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        filter: brightness(0.4);
    }
}

.headline {
    position: absolute;
    bottom: 12%;
    left: 8%;
    color: var(--light);
    max-width: 60rem;
    & h2 {
        text-transform: uppercase;
        padding-bottom: 2rem;
        @media (max-width: 62.5rem) {
            padding-bottom: 1rem;
        }
    }
}
