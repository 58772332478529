.announcements {
    position: relative;

    /* Carousel Wrapper */
    &__carousel {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        /* Individual Card */
        &__card {
            display: none;
            width: 100%;
            max-width: 60rem;
            height: 100%;
            overflow: hidden;
            margin: 0 auto;

            &.active {
                display: flex;
                animation: fadeIn 0.5s ease-in-out;
                @media (max-width: 62.5rem) {
                    flex-direction: column;
                }
            }

            /* Image */
            .card__image {
                width: 100%;
                max-width: 25rem;
                min-width: 25rem;
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
                @media (max-width: 62.5rem) {
                    max-width: 100%;
                    min-width: 100%;
                    height: 18rem;
                }
            }

            /* Content */
            .card__content {
                padding: 1.75rem;
                padding-left: 2.5rem;
                display: flex;
                flex-direction: column;
                @media (max-width: 62.5rem) {
                    padding: 1.75rem;
                    padding-bottom: 0;
                }
            }
        }
    }

    /* Arrows */
    &__arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        color: var(--primary);
        background: none;
        height: 100%;
        font-size: 3.4rem;
        cursor: pointer;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;

        &--left {
            left: 1rem;
        }

        &--right {
            right: 1rem;
        }

        &:hover {
            color: var(--secondary);
        }

        @media (max-width: 62.5rem) {
            top: -4rem;
            height: 6rem;
        }
    }

    /* Pagination Dots */
    &__pagination {
        display: flex;
        justify-content: center;
        gap: 0.75rem;
        margin-top: 2rem;

        &__dot {
            width: 0.75rem;
            height: 0.75rem;
            background-color: hsl(0, 0%, 88%);
            cursor: pointer;
            transition: background-color 0.3s ease-in-out;

            &.active {
                background-color: var(--primary);
            }

            &:hover {
                background-color: var(--primary);
            }
        }
    }

    /* Animation */
    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: scale(0.95);
        }
        to {
            opacity: 1;
            transform: scale(1);
        }
    }
}