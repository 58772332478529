.event {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5.375rem;
    gap: 2.6875rem;
    &__info {
        display: flex;
        flex-direction: column;
        gap: 1.8rem;
        width: 60%;
    }
    &__image {
        width: 100%;
        max-width: 20rem;
        object-fit: cover;
        border-radius: 0.25rem;
        background-color: var(--primary-light);
        border: 4px solid var(--primary);
    }
    &__date {
        text-align: center;
        padding: 0.3rem 0.8rem;
        color: var(--light);
        background-color: var(--secondary);
        width: fit-content;
        margin-bottom: 0;
    }
    @media (max-width: 62.5rem) {
        flex-direction: column;
        gap: 1.8rem;
        &__info {
            width: 100%;
        }
    }
}